<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">申遗承诺信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="承诺事项来源类型" prop="cnsxlylx">
                    <el-select
                        v-model="formdata.saveJson.cnsxlylx"
                        filterable
                        clearable
                        :disabled="!isEdit"
                        placeholder="请选择承诺事项来源类型"
                        @change="changeCNSXLY"
                    >
                        <el-option
                            label="大会决议"
                            value="大会决议"
                        ></el-option>
                        <el-option
                            label="申遗文本"
                            value="申遗文本"
                        ></el-option>
                        <el-option
                            label="申遗补充材料"
                            value="申遗补充材料"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="承诺事项来源" prop="cnsxlyid">
                    <el-select
                        v-model="formdata.saveJson.cnsxlyid"
                        filterable
                        clearable
                        :disabled="!isEdit"
                        placeholder="请选择承诺事项来源"
                        @change="changeCNSXLYWJ"
                    >
                        <el-option
                            v-for="(item, index) in cnsxlyList"
                            :key="index"
                            :label="item.ZLMC"
                            :value="item.SJID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="采纳文件时间" prop="cnwjsj">
                    <el-date-picker
                        v-model="formdata.saveJson.cnwjsj"
                        :disabled="!isEdit"
                        value-format="yyyy"
                        type="year"
                        placeholder="请选择采纳文件时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item
                    label="承诺完成时间"
                    prop="cnwcsj"
                    class="timeselect_none"
                >
                    <el-date-picker
                        v-model="cnwcsj_date"
                        :disabled="cnwcsj || !isEdit"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="请选择承诺完成时间"
                        @change="formdata.saveJson.cnwcsj = cnwcsj_date"
                    ></el-date-picker>
                    <el-checkbox
                        v-model="cnwcsj"
                        :disabled="!isEdit"
                        @change="changeNotime"
                        >无</el-checkbox
                    >
                </el-form-item>
                <el-form-item
                    label="承诺事项内容（英文）"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.saveJson.cnsxnr_yw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        :maxlength="2000"
                        show-word-limit
                        placeholder="请输入承诺事项内容（英文）"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="承诺事项内容（中文）"
                    prop="cnsxnr_zw"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.saveJson.cnsxnr_zw"
                        :disabled="!isEdit"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        :maxlength="2000"
                        show-word-limit
                        placeholder="请输入承诺事项内容（中文）"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "dedication_commitment",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            rules: {
                cnsxlylx: [
                    {
                        required: true,
                        message: "请选择承诺事项来源类型",
                        trigger: "change",
                    },
                ],
                cnsxlyid: [
                    {
                        required: true,
                        message: "请选择承诺事项来源",
                        trigger: "change",
                    },
                ],
                cnwjsj: [
                    {
                        required: true,
                        message: "请选择采纳文件时间",
                        trigger: "change",
                    },
                ],
                cnwcsj: [
                    {
                        required: true,
                        message: "请选择承诺完成时间",
                        trigger: "change",
                    },
                ],
                cnsxnr_zw: [
                    {
                        required: true,
                        message: "请输入承诺事项内容（中文）",
                        trigger: "blur",
                    },
                ],
            },
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnsxlyList: [],
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                    cnsxlylx: "",
                    cnsxlyid: "",
                    cnly: "", // 承诺事项来源对应文件名
                    cnwjsj: "",
                    cnwcsj: "",
                    cnsxnr_yw: "",
                    cnsxnr_zw: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "33",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    watch: {
        "formdata.saveJson.cnsxlylx": function() {
            this.getList(this.formdata.saveJson.cnsxlylx);
        },
    },
    mounted() {},
    methods: {
        ...mapActions(["getcnsxlyList"]),
        changeNotime(value) {
            // 选择无--清空表单
            if (value) {
                this.formdata.saveJson.cnwcsj = "无";
                this.cnwcsj_date = "";
                this.rules.cnwcsj[0].required = false;
            } else {
                this.formdata.saveJson.cnwcsj = "";
                this.rules.cnwcsj[0].required = true;
            }
        },
        changeCNSXLYWJ(value) {
            this.formdata.saveJson.cnly = this.cnsxlyList.filter(
                (item) => item.SJID === value
            )[0].ZLMC;
        },
        changeCNSXLY(value) {
            this.formdata.saveJson.cnsxlyid = "";
        },
        // 获取承诺事项来源列表
        async getList(value) {
            let res = await this.getcnsxlyList({
                ycdbm: this.heritageId,
                cnlylx: value,
            });
            this.cnsxlyList = res.ResultValue;
        },
    },
};
</script>
<style lang="scss" scoped>
.basic_data_edit {
    // border:1px solid red;
}
</style>
